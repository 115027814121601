import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'martDate'
})
export class MartDatePipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} type
   * @param {string} key
   *
   * @returns {any}
   */
  transform(items: string, ind): any {
    if (!items) return '';

    let date = '';
    date += '/'+items.substring(0, 4);
    date = '/'+items.substring(4, 6)+date;
    date = items.substring(6, 8)+date;
    return date;
  }
}
