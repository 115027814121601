import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { DataManagementService } from 'app/services/data-management';
import { environment } from 'environments/environment';
import { ShopService } from 'app/services/shop.service';
@Component({
  selector: 'app-item-sidebar',
  templateUrl: './item-sidebar.component.html',
  styleUrls: ['./item-sidebar.component.scss']
})
export class ItemSidebarComponent implements OnInit, OnChanges {
  @Input() item: any = {};
  @Input() categories: any[] = [];
  @Input() isEdit: boolean;
  subCategories: any[] = [];


  selectedCategory: any = '';
  selectedSubCategory: any = '';
  goodsName: any = '';
  price: any = '';
  fee: any = '';
  isGST: boolean = true;
  constructor(private sidebarService: CoreSidebarService,
    private data_manager: DataManagementService,
    private shopService: ShopService) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.item){
      this.goodsName = this.item['goods_name'];
      this.price = this.item['price'];
      this.fee = this.item['fee_env'];
      this.isGST = this.item['tax_id'] == 'bc-g' ? true : false;
      this.selectedCategory = this.item['pcid'];
      this.selectedSubCategory = this.item['cid'];
      this.isEdit = false;
      this.getItemDetail();
      // this.setSelectedCate();

    }else{
      this.isEdit = true;
      this.goodsName = '';
      this.price = '';
      this.fee = '';
      this.isGST = true;
      this.selectedCategory = '';
      this.selectedSubCategory = '';
      this.subCategories = [];

    }
  }


  updatePrice(){

  }

  toggleEventSidebar(){
    this.sidebarService.getSidebarRegistry('item-sidebar-edit').toggleOpen();

  }

  setSelectedCate(){
    this.subCategories = [];
    console.log(this.selectedCategory)
    for(let category of this.categories){
      if(category.id == this.selectedCategory){
        console.log(category)
        this.subCategories = category.children;
      }
    }
  }

  createItem(){   
    if(!this.goodsName){ 
      alert('Item can not be empty');
      return
    }
    let data = [
      {
        spid: this.shopService.spid,
        goodsName: this.goodsName,
        pcid: this.selectedSubCategory,
        cid: '1082'
      }
    ]
    if(!this.isEdit){
      data[0]['id'] = this.item['goods_id'];
    }

    let formData: any = new FormData();
    formData.append('updates', JSON.stringify(data));

    this.data_manager.httpRequest(environment.shopUrl+'/m/prod/upsert', 'post', formData, true).subscribe((res)=>{
      console.log(res)
      if(res['code'] == 0){
        this.createSKU(res['msg'][0]);
        // this.createSKU('0');
      }
    }
    )


  }


  createSKU(goodsId){
    let data = [
      {
        goods_name: this.goodsName,
        goods_id: goodsId,
        price: this.price,
        status: '1',
        // tax_id: 'bc-g',
        // fee_env: this.fee,
        //stock is set to max long value
        stock: 	2147483647,
      }
    ]
    if(this.isGST){
      data[0]['tax_id'] = 'bc-g';
    }
    if(this.fee){
      data[0]['fee_env'] = this.fee;
    }
    if(!this.isEdit){
      data[0]['id']= this.item['id'];
      data[0]['goods_id']= this.item['goods_id'];
    }
    let formData: any = new FormData();
    formData.append('updates', JSON.stringify(data));
    this.data_manager.httpRequest( environment.shopUrl+'/m/prod/sku-upsert', 'post', formData, true).subscribe((res)=>{
      console.log(res)
      if(res['code'] == 0){
        if(!this.isEdit){
          alert('Item updated successfully');
        }else{
          alert('Item created successfully');
        }

        this.toggleEventSidebar();
      }
    }
    )
  }

  getItemDetail(){
    let formData: any = new FormData();

    // console.log(item_ids.toString())
    formData.append('attrIds', this.item.id);
    this.data_manager.httpRequest( environment.apiUrl+'/mall/category-ex/detail', 'post', formData, false).subscribe((res)=>{
      if(res['code'] == 0){
        console.log(res);
        for(let itemDetail of res['msg']){

            if(this.item.id == itemDetail.attrId){
              // 'selectedCate': '',
              // 'selectedSubCate': '',
              // 'subCate':[],
              // 'serviceList':[],
              // 'selectedService': '',
              for(let cate of this.categories){
                if(cate.title == itemDetail.rscTitle){
                  this.selectedCategory = cate.id;
                  this.subCategories = [...cate.children];
                  for(let subCate of cate.children){
                    console.log(subCate)
                    if(subCate.title == itemDetail.scTitle){
                      this.selectedSubCategory = subCate.id;
                      // this.selectedService = item.id
                    }
                  }
                }
              }
              // item.subCate = itemDetail.subCate;
              // item.serviceList = itemDetail.serviceList;
            }
        }
      }
      console.log(res)
      
    }
    )
  }

}
