import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import { Router } from "@angular/router";
import { AuthenticationService } from 'app/auth/service';

@Injectable({
  providedIn: 'root'
})
export class GlobalHttpInterceptorService implements HttpInterceptor{

  constructor(public router: Router,
    private _authenticationService: AuthenticationService,
    private _router: Router,

    ) {
  }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    // const token: string = 'invald token';
    // req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
 
    return next.handle(req).pipe(
      catchError((error) => {
 
        let handled: boolean = false;
        console.error(error);
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this._authenticationService.logout();
                this._router.navigate(['/pages/authentication/login']);
                // console.log(`redirect to login`);
                handled = true;
                break;
              case 403:     //forbidden
                this._authenticationService.logout();
                this._router.navigate(['/pages/authentication/login']);
                // console.log(`redirect to login`);
                handled = true;
                break;
              case 0:        //error Access-Control-Allow-Origin
                this._authenticationService.logout();
                this._router.navigate(['/pages/authentication/login']);
                // console.log(`redirect to login43`);
                handled = true;
                break;
            }
          }
        }
        else {
          console.error("Other Errors");
        }
 
        if (handled) {
          console.log('return back ');
          return of(error);
        } else {
          console.log('throw error back to to the subscriber');
          return throwError(error);
        }
 
      })
    )
  }
}