export const locale = {
  lang: 'pt',
  data: {
    MENU: {
      HOME: 'Casa',
      SAMPLE: 'Amostra'
    },
    HOME: 'home3'
  }
}
