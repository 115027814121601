import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'epochToLocal'
})
export class EpochToLocalPipe implements PipeTransform {
  timeZone: string = JSON.parse(localStorage.getItem('currentUser'))?.info.zone;
  transform(epochTime): unknown {
    return moment.unix(epochTime).tz(this.timeZone).format('lll');
  }

}
