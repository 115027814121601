import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'getCategory',
    pure: false
})
export class GetCategoryPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} categories
   * @param {string} type
   * @param {string} key
   *
   * @returns {any}
   */
  transform(categories: any[], keys: string[]): any {
    // console.log('cate --', categories, keys);
    let return_str = "";
    for(let key of keys){
        for(let category of categories){
            if(category.id == key){
                return_str += category.title + " ";
            }
        }
    }
    return return_str;
  }
}
