import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
@Pipe({
  name: 'utcToLocal'
})
export class UtcToLocalPipe implements PipeTransform {

  /**
   * convert utc time to local time 
   * @param couponInfo 
   * @returns 
   */
  transform(couponInfo: any): any {
    // console.log(couponInfo);
    let utcTime = Number(couponInfo.expire_start) + Number(couponInfo.expire_dur)
    let localTime = moment.unix(utcTime).local()
    return localTime
    
  }

}
