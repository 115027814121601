<div class="modal-dialog sidebar-lg">
    <div class="modal-content p-0">
        <!-- START: Modal Header -->
        <div class="setting" data-bs-scroll="true" tabindex="-1" aria-labelledby="offcanvasBothLabel">
            <div class="offcanvas-header fixed custom">
                <h4 id="offcanvasBothLabel" class="offcanvas-title fw-bolder">Message Center</h4>
                <button type="button" class="btn-close text-reset"  data-bs-dismiss="offcanvas" aria-label="Close" (click)="toggleEventSidebar()"></button>
            </div>
        </div>
        <!-- END: Modal Header -->

        <!-- START: Modal Body -->
        <div class="offcanvas-body mx-0 flex-grow-0">

            <!-- Select Type -->
            <div class="mb-1">
                <label class="form-label">Message Type</label>
                <ng-select class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-select-bottom ng-select-opened" 
                [(ngModel)]="selectedType" 
                
                >
                    <ng-option *ngFor="let type of messageType" [value]="type.id">{{type.name}}</ng-option>
                </ng-select>

                <!-- <div class="custom-control custom-control-primary custom-radio">
                    <input
                        type="radio"
                        id="pushNotify"
                        name="pushNotify"
                        class="custom-control-input"
                        checked
                    />
                    <label class="custom-control-label" for="pushNotify">App Push Notification</label>
                </div>

                <div class="custom-control custom-control-primary custom-radio">
                <input
                    type="radio"
                    id="smsNotify"
                    name="smsNotify"
                    class="custom-control-input"
                />
                <label class="custom-control-label" for="smsNotify">SMS Notification</label>
                </div> -->
            </div>

            <!-- Title -->
            <div class="mb-1">
                <label class="form-label">Message Title</label>
                <ng-select class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-select-bottom ng-select-opened" 
                    [(ngModel)]="selectedMessageId" 
                    (change)="updateMessage()"
                >
                    <ng-option *ngFor="let item of messageTemplates" [value]="item.id">{{item.title}}</ng-option>
                </ng-select>
            </div>

            <!-- Preview -->
            <div class="mb-1">
                <label *ngIf="selectedMessage" class="form-label">Message Preview</label>
                <h5 *ngIf="selectedType==1">
                    {{selectedMessage?.content}}
                </h5>
                <h5 *ngIf="selectedType==3">
                    {{selectedMessage?.content}}
                </h5>
            </div>

            <div class="mt-3" *ngIf="selected_order">
                <textarea placeholder="Please enter admin notes." rows="10" [(ngModel)]="selected_order.activity_info"></textarea>

                <button type="button" class="btn btn-flat-primary mt-1 d-grid w-100" (click)="saveNotes()">Save Notes</button>
            </div>
        </div>



        <!-- END: Modal Body -->

        <!-- START: Modal Footer -->
        <div class="offcanvas-footer">
            <button type="button" class="btn btn-primary mb-1 d-grid w-100" (click)="sendMessage()">Send Message</button>
            <!-- <button type="button" class="btn btn-primary mb-1 d-grid w-100" (click)="createSKU(item.goods_id)">{{ "SUBMIT" | translate }}</button> -->
            <button type="button" class="btn btn-outline-primary mb-1 d-grid w-100" (click)="toggleEventSidebar()">Cancel</button>
        </div>
        <!-- End: Modal Footer -->
    </div>

</div>