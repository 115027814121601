import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

import { WebcamModule } from 'ngx-webcam';
import { GoogleMapsModule } from '@angular/google-maps';
import {PlatformModule} from '@angular/cdk/platform';
import { OpenOrdersComponent } from './main/open-orders/open-orders.component';
import { OrderHistoryComponent } from './main/order-history/order-history.component';
import { ShopServicesComponent } from './main/shop-services/shop-services.component';
import { StorefrontSettingsComponent } from './main/storefront-settings/storefront-settings.component';
import { CustomersComponent } from './main/customers/customers.component';
import { InvoiceComponent } from './main/invoice/invoice.component';
import { SidebarsModule } from './main/sidebars/sidebars.module';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { GlobalHttpInterceptorService } from './services/global-http-interceptor.service';


const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path:'sample',
    loadChildren: () => import('./main/sample/sample.module').then(m => m.SampleModule),
  },
  {
    path: 'open-orders',
    loadChildren: () => import('./main/open-orders/open-orders.module').then(m => m.OpenOrdersModule),
  },
  {
    path: 'order-history',
    loadChildren: () => import('./main/order-history/order-history.module').then(m => m.OrderHistoryModule),
  },
  {
    path: 'shop-services',
    loadChildren: () => import('./main/shop-services/shop-services.module').then(m => m.ShopServicesModule),
  },
  {
    path: 'coupons',
    loadChildren: () => import('./main/coupons/coupons.module').then(m => m.CouponsModule),
  },
  {
    path: 'routes',
    loadChildren: () => import('./main/rider/rider.module').then(m => m.RiderModule),
  },
  // {
  //   path: 'route-history',
  //   loadChildren: () => import('./main/rider/route-history/route-history.module').then(m => m.RouteHistoryModule),
  // },
  {
    path: 'storefront-settings',
    loadChildren: () => import('./main/storefront-settings/storefront-settings.module').then(m => m.StorefrontSettingsModule),
  },
  // {
  //   path: 'timesheets',
  //   loadChildren: () => import('./main/rider/timesheets/timesheets.module').then(m => m.TimesheetsModule),
  // },
  {
    path: 'customers',
    loadChildren: () => import('./main/customers/customers.module').then(m => m.CustomersModule),

  },
  {
    path: 'invoice',
    loadChildren: () => import('./main/invoice/invoice.module').then(m => m.InvoiceModule),
  },
  {
    path: 'cate-management',
    loadChildren: () => import('./main/cate-management/cate-management.module').then(m => m.CateManagementModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m=>m.DashboardModule),
  },
  {
    path: 'manual',
    loadChildren: () => import('./main/manual/manual.module').then(m=>m.ManualModule),
  },
  {
    path: '',
    redirectTo: '/open-orders',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy',
      useHash: true
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SidebarsModule,

    // webcam
    WebcamModule,

    // GoogleMapsModule
    GoogleMapsModule,

    // detect device
    PlatformModule,

    // fake backend
    // HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
    //   delay: 0,
    //   passThruUnknownUrl: true
    // }),
    
  ],
  bootstrap: [AppComponent],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true  }] //cooper fakeBackendProvider
})
export class AppModule {}
