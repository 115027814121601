import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  spid: string = ''
  constructor() {
    this.spid = JSON.parse(localStorage.getItem('currentUser'))?.info?.id ?? ''
    console.log('shop service', this.spid);

   }
}
