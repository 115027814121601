import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(unix: number, format: string): string {
    return moment.unix(unix).format(format);
  }

}
