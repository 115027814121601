<!-- 做一个inventory import 和 export，还有update -->
<div class="modal-dialog sidebar-lg">
    <div class="modal-content p-0">
        <!-- START: Modal Header -->
        <div class="setting" data-bs-scroll="true" tabindex="-1" aria-labelledby="offcanvasBothLabel">
            <div class="offcanvas-header fixed custom">
                <h4 id="offcanvasBothLabel" class="offcanvas-title fw-bolder">{{title}}</h4>
                <button type="button" class="btn-close text-reset"  data-bs-dismiss="offcanvas" aria-label="Close" (click)="toggleEventSidebar()"></button>
            </div>
        </div>
        <!-- END: Modal Header -->

        <!-- START: Modal Body -->
        <div class="offcanvas-body mx-0 flex-grow-0">

            <ng-content></ng-content>

        </div>
        <!-- END: Modal Body -->

        <!-- START: Modal Footer -->
        <div class="offcanvas-footer">
            <button type="button" class="btn btn-primary mb-1 d-grid w-100" (click)="confirm()">{{ confirm_text }}</button>
            <button type="button" class="btn btn-outline-primary mb-1 d-grid w-100" (click)="toggleEventSidebar()">Cancel</button>
        </div>
        <!-- End: Modal Footer -->
    </div>

</div>