import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'adjustmentPrice'
})
export class AdjustmentPricePipe implements PipeTransform {
  transform(x: number): any {
    let y = x
    y = Math.floor(y * 10);
    return Math.floor(y/10) + (y%10 ? 1:0)
  }

}
