import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'city'
})
export class CityPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} type
   * @param {string} key
   *
   * @returns {any}
   */
  transform(items: any, type: string, key: string): any {
    if (!items) return '';
    if (!type) return items;

    let keys = items.split("|")
    if(type == 'name'){
        return keys[0]
    }
    if(type == 'addr'){
      return items.replace(/\|/g, ', ')
    }
    return items;
  }
}
