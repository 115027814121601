export const locale = {
  lang: 'en',
  data: {
    ORDERS: 'Orders',
    BUILDINGS: 'Buildings',
    DELIVERIES: 'Deliveries',
    COMPLETED:'Completed',
    INCOMPLETE:'Incomplete',
    CANCEL:'Cancel',
    DELETE:'Delete',
    WARNING: 'Warning',
    OKAY: 'Okay',
    TOTAL: 'Total',
    CALL: 'Call',
    ITEM: 'Item',
    AMOUNT: 'Amount',
    QUANITY: 'Quantity',
    SHOW_MORE: 'Show More',
    SUBMIT: 'Submit',
    HISTORY: 'History',
    MENU: {
      HOME: 'Home',
      DELIVERIES: 'Deliveries',
      SAMPLE: 'Sample',
      INDEX: 'Index'
    },
    BUILDING: {
      B_DETAILS: 'Building Details',
      COMPLETE_ALL: 'Complete All',
      SEARCH_BY: "search by phone/unit/id, divide long list into pages",
      SEARCH: 'Search',
      ORDER: 'Order',
      CLIENT_PHONE: 'Client Phone',
      UNIT: 'Unit',
      BUZZ: 'Buzz',
      STATUS: 'Status',
      UPDATE_DELIVERY_STATUS: 'Update Delivery Status Reminder',
      MARK_ALL_ORDERS_COMPLETE: 'Mark all orders to complete for this building?',
      MARK_COMPLETE: 'Mark Complete',
      BUILDING_NOT_COMPLETE: 'Building not complete',
      MARK_ORDERS_COMPLETE: 'Mark orders complete before proceeding to the next building.'
    },
    LOGIN: {
      TITLE: 'Login',
      USERNAME: 'Username',
      PASSWORD: 'Password',
      LOGIN: 'Login',
      SING_IN: 'Sign in',
      FORGOT_PASSWORD: 'Forgot Password',
      REGISTER: 'Register',
      PHONE_NUMBER: 'Phone Number',
      ERROR_USERNAME: 'Phone number is required',
      ERROR_PASSWORD: 'Password is required',
      LOGOUT: 'Logout',
    },
    ORDER_DETAILS: {
      UNIT_NUMBER: 'Unit Number',
      CONTACT_NUMBER: 'Contact Number',
      IN_PROGRESS: 'In Progress',
      DELIEVERED: 'Delivered',
      IMPORT: 'Import',
      UPLOAD: 'Upload',
      UPLOAD_PHOTO: 'Upload Photo',
      ORDER_STATUS: 'Order Status',
      ALL_PACKAGE_HANDED: 'All Packages handed to client.',
      PARTIAL_PACKAGES_HANDED: 'Partial packages handed to client.',
      ALL_PACKAGES_LEFT: 'All Packages left at mailroom.',
      ORDER_INFORMATION: 'Order Information',
      BUILDING_NAME: 'Building Name',
      BUILDING_ADDRESS: 'Building Address',
      CLIENT_NAME: 'Client Name',
      CLIENT_PHONE: 'Client Phone',
      GIFT_RECEIVER_NAME: 'Gift Receiver Name',
      GIFT_RECEIVER_PHONE: 'Gift Receiver Phone',
      ORDER_SUMMARY: 'Order Summary',
      END_ORDER_INFORMATION: 'End of order information',
      DELIVERY_NOTES: 'Delivery Notes',
    }
  }
}
