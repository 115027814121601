import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { CityPipe } from './city.pipe';
import { UtcToLocalPipe } from './utc-to-local.pipe';
import { MartDatePipe } from './martDate.pipe';
import { EpochToLocalPipe } from './epoch-to-local.pipe';
import { InvoiceAddressPipe } from './invoiceAddress.pipe';
import { GetCategoryPipe } from './getCategory.pipe';
import { GetDatePipe } from './getDate.pipe';
import { StatusMapPipe } from './status-map.pipe';
import { AdjustmentPricePipe } from './adjustment-price.pipe';




@NgModule({
  declarations: [InitialsPipe, FilterPipe, 
    StripHtmlPipe, SafePipe, CityPipe, 
    MartDatePipe, InvoiceAddressPipe, GetCategoryPipe, 
    UtcToLocalPipe, EpochToLocalPipe, GetDatePipe, StatusMapPipe, AdjustmentPricePipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, 
    SafePipe, CityPipe, MartDatePipe, InvoiceAddressPipe,
    GetCategoryPipe, UtcToLocalPipe, EpochToLocalPipe, GetDatePipe, StatusMapPipe, AdjustmentPricePipe]
})
export class CorePipesModule {}
