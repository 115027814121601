<div class="modal-dialog sidebar-lg">
    <div class="modal-content p-0">
        <!-- START: Modal Header -->
        <div class="setting" data-bs-scroll="true" tabindex="-1" aria-labelledby="offcanvasBothLabel">
            <div class="offcanvas-header fixed custom">
                <h4 id="offcanvasBothLabel" class="offcanvas-title fw-bolder">{{isEdit?"Add":"Edit"}} Category</h4>
                <button type="button" class="btn-close text-reset"  data-bs-dismiss="offcanvas" aria-label="Close" (click)="toggleEventSidebar()"></button>
            </div>
        </div>
        <!-- END: Modal Header -->

        <!-- START: Modal Body -->
        <div class="offcanvas-body mx-0 flex-grow-0">

            <div class="mb-1">
                <label class="form-label">Service Type</label>
                <ng-select class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-select-bottom ng-select-opened" 
                    [(ngModel)]="selectedCategory" 
                    >
                    <ng-option *ngFor="let category of categories" [value]="category.id">{{category.title}}</ng-option>
                </ng-select>
            </div>

            <div class="mb-1">
                <label class="form-label" for="category-field">Service Category</label>
                <input  class="form-control" id="category-field"  [(ngModel)]="categoryName" placeholder="Enter Category Name">
            </div>

        </div>
        <!-- END: Modal Body -->

        <!-- START: Modal Footer -->
        <div class="offcanvas-footer">
            <button type="button" class="btn btn-primary mb-1 d-grid w-100" (click)="createCategory()">{{ "Submit" | translate }}</button>
            <button type="button" class="btn btn-outline-primary mb-1 d-grid w-100" (click)="toggleEventSidebar()">Cancel</button>
        </div>
        <!-- End: Modal Footer -->
    </div>

</div>