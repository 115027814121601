import { Component, Input, OnInit } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { AuthenticationService } from 'app/auth/service';
import { messageTemplates } from 'app/data/message-template';
import { DataManagementService } from 'app/services/data-management';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-message-sidebar',
  templateUrl: './message-sidebar.component.html',
  styleUrls: ['./message-sidebar.component.scss']
})
export class MessageSidebarComponent implements OnInit {
  @Input() selected_order: any = {};
  shopInfo: any = {}
  notes: any = '';
  messageType = 
  [
    {
      id: 1,
      name: 'SMS'
    },
    // {
    //   id: 2,
    //   name: 'Email'
    // },
    {
      id: 3,
      name: 'Push Notification'
    }
  ]


  public selectedType:any= '';
  public selectedMessageId:any= '';
  public selectedMessage:any= '';
  public messageTemplates = messageTemplates;


  constructor(private sidebarService: CoreSidebarService,
    private data_manager: DataManagementService,
    private authService: AuthenticationService,
    private toastrServe: ToastrService) { }

  ngOnInit(): void {
    this.shopInfo = JSON.parse(localStorage.getItem('currentUser'))?.info
  }

  setSelectedType(){
    if(this.selectedType=='1' || this.selectedType=='3'){
      this.messageTemplates = messageTemplates;
    }else{
      this.messageTemplates = messageTemplates;
    }
  }

  updateMessage(){
    console.log(this.messageTemplates);
    this.messageTemplates = JSON.parse(JSON.stringify(messageTemplates)); 
    for(let message of this.messageTemplates){
      if(message.id == this.selectedMessageId){
        let message_list = message.content.split('{{}}');
        console.log(message_list);
        if(this.selectedMessageId == 2){
          message.content = message_list[0] + this.selected_order.id + message_list[1]
        }else{
          message.content = message_list[0] + this.selected_order.id + message_list[1] + this.shopInfo.title + message_list[2] + this.shopInfo.mobile;
        }
        this.selectedMessage = message;

        console.log(message);
      }
    }
  }

  toggleEventSidebar(): void {
    this.sidebarService.getSidebarRegistry('message-sidebar').toggleOpen();
  }

  sendMessage(){
    if(this.selectedType=='1'){
      console.log('send sms')
      this.sendSMS();
    }else if(this.selectedType=='2'){
      this.sendEmail();
    }else if(this.selectedType=='3'){
      this.sendPushNotification();
    }
    
  }

  sendSMS(){
    let formData: any = new FormData();
    formData.append('mobile', this.selected_order.mobile);
    formData.append('msg', this.selectedMessage.content);
    this.data_manager.httpRequest( environment.shopUrl+'/m/notification/sms', 'post', formData, true).subscribe((res)=>{
      console.log(res);
      if(res.code == 0){
        this.toastrSuccess('Send SMS')
      } else {
        this.toastrError('Fail to send SMS')
      }
    }
    )
  }

  sendEmail(){
  }

  sendPushNotification(){
    let formData: any = new FormData();
    formData.append('mobile', this.selected_order.mobile);
    formData.append('title', this.selectedMessage.title);
    formData.append('msg', this.selectedMessage.content);
    formData.append('type', 'BLUEBOX');
    this.data_manager.httpRequest( environment.shopUrl+'/m/notification/push', 'post', formData, true).subscribe((res)=>{
      console.log(res);
      if(res.code == 0){
        this.toastrSuccess('Send Push Notification')
      } else {
        this.toastrError('Fail to send Push Notification')
      }
    }
    )
  }

    // Success
    toastrSuccess(message: any) {
      this.toastrServe.success(message, 'Success!', {
        toastClass: 'toast ngx-toastr',
        closeButton: true
      });
    }
  
    // fail
    toastrError(message: any) {
      this.toastrServe.error(message, 'Error!', {
        toastClass: 'toast ngx-toastr',
        closeButton: true
      });
    }

    saveNotes(){
      let order = [{id: this.selected_order.id, activity_info: this.selected_order.activity_info}];
      let formData: any = new FormData();
      formData.append('updates', JSON.stringify(order));
      // formData.append('activity_info', this.notes);

      this.data_manager.httpRequest(environment.shopUrl+'/m/order/upsert', 'post', formData, true).subscribe((res)=>{
        console.log(res);
        if(res.code == 0){
          this.toastrSuccess('Saved Notes')
        } else {
          this.toastrError('Fail to save Notes')
        }
      });
    }

}
