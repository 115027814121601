import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { CoreCommonModule } from "@core/common.module";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgSelectModule } from "@ng-select/ng-select";
import { CoreSidebarModule } from "@core/components";
import { CategorySidebarComponent } from "./category-sidebar/category-sidebar.component";
import { ItemSidebarComponent } from "./item-sidebar/item-sidebar.component";
import { MessageSidebarComponent } from "./message-sidebar/message-sidebar.component";
import { CustomSidebarComponent } from "./custom-sidebar/custom-sidebar.component";

@NgModule({
  declarations: [
    CategorySidebarComponent,
    ItemSidebarComponent,
    MessageSidebarComponent,
    CustomSidebarComponent,
  ],
  imports: [
    TranslateModule,
    CoreCommonModule,
    NgbModule,
    NgxDatatableModule,
    NgSelectModule,
    CoreSidebarModule,
  ],
  exports: [
    CategorySidebarComponent,
    ItemSidebarComponent,
    MessageSidebarComponent,
    CustomSidebarComponent,
  ],
})
export class SidebarsModule {}
