import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { DataManagementService } from 'app/services/data-management';
import { environment } from 'environments/environment';
import { ShopService } from 'app/services/shop.service';
@Component({
  selector: 'app-category-sidebar',
  templateUrl: './category-sidebar.component.html',
  styleUrls: ['./category-sidebar.component.scss']
})
export class CategorySidebarComponent implements OnInit, OnChanges {
  @Input() categories: any[] = [];
  @Input() selectedCate: any = '';
  isEdit: boolean = true;
  selectedCategory: any = '';
  categoryName: any = '';
  constructor(private data_manager: DataManagementService,
    private sidebarService: CoreSidebarService,
    private shopService: ShopService) { }


  ngOnInit(): void {
  }

  toggleEventSidebar() {
    this.sidebarService.getSidebarRegistry('category-sidebar-edit').toggleOpen();
  }


  ngOnChanges(){
    if(this.selectedCate){
      this.selectedCategory = this.selectedCate.pid;
      this.categoryName = this.selectedCate.title;
      this.isEdit = false;
    }else{
      this.isEdit = true;
      this.selectedCategory = '';
      this.categoryName = '';
    }

  }

  createCategory(){
    if(!this.isEdit){
      this.updateCategory();
      return;
    }
    console.log(this.selectedCategory);
    console.log(this.categoryName)
    this.selectedCategory = this.selectedCategory?this.selectedCategory:'0';
    if(!this.categoryName||!this.selectedCategory){
      alert('Service type and category name are required!')
      return;
    }
    let data = [
      {
        title: this.categoryName,
        spid: this.shopService.spid,
        pid: this.selectedCategory
      }
    ]
    let formData: any = new FormData();
    formData.append('updates', JSON.stringify(data));
    this.data_manager.httpRequest( environment.shopUrl+'/m/category/upsert', 'post', formData, true).subscribe((res)=>{
      console.log(res)
      if(res['code'] == 0){
        alert('Create category successfully!')
        this.toggleEventSidebar();
      }
    });
  }

  updateCategory(){
    let data = [
      {
        title: this.categoryName,
        spid: this.shopService.spid,
        id: this.selectedCate.id,
        pid: this.selectedCategory
      }
    ]
    let formData: any = new FormData();
    formData.append('updates', JSON.stringify(data));
    this.data_manager.httpRequest( environment.shopUrl+'/m/category/upsert', 'post', formData, true).subscribe((res)=>{
      console.log(res)
      if(res['code'] == 0){
        alert('Update category successfully!')
        this.toggleEventSidebar();
      }
    });
  }


}
