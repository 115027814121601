import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'getDate'
})
export class GetDatePipe implements PipeTransform {
  transform(statusList:any[], stat): unknown {
    for(let status of statusList){
        if (status.value == stat){
            return status.updated_at
        }
    }
  }

}
