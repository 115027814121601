import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceAddress'
})
export class InvoiceAddressPipe implements PipeTransform {

  transform(fullAddress: string, indicator: number): any {
    // if indicator is 0, return the first part of the address before first |, 
    // else return the second part of the address after first |
    if(indicator == 0){
        return fullAddress.split(' |')[1];
    }else{
        let temp = fullAddress.split(' |');
        temp = temp.slice(2, temp.length-1);
        return temp.join(',');
    }
  }
}