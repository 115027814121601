import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusMap'
})
export class StatusMapPipe implements PipeTransform {

  transform(status): unknown {
    let statusMap = {
      initial: 'Await For Drop-Off',
      pending: 'Pending',
      assigned:'Assigned',
      picked_up: 'Picked Up',
      unpaid: 'Unpaid',
      paid: 'Paid',
      ready_to_deliver:'Ready to Deliver',
      delivered: 'Delivered',
      taken: 'Taken',
      cancelled: 'Cancelled',
      failed: 'Failed'

    }
    return statusMap[status];
  }

}
