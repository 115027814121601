import { CoreMenu } from '@core/types'

export const menuBlueMart: CoreMenu[] = [

  { 
    id: 'operation',
    type: 'section',
    title: 'OPERATION',
    children:[
      {
        id: 'orders',
        title: 'Orders',
        type: 'collapsible',
        icon: 'shopping-cart',
        children:[
          {
            id: 'open_orders',
            title: 'Open Orders',
            type: 'item',
            icon: 'circle',
            url: 'open-orders'
          },
          {
            id: 'order_history',
            title: 'Order History',
            type: 'item',
            icon: 'circle',
            url: 'order-history'
          }
        ]
      },
      
      {
        id: 'coupons',
        title: 'Coupons',
        type: 'item',
        icon: 'award',
        url: 'coupons'
      },
      { 
        id: 'customers',
        title: 'Customers',
        type: 'item',
        icon: 'users',
        url: 'customers'
      },

   
  ]
  },
  { 
    id: 'office',
    type: 'section',
    title: 'OFFICE',
    children:[
      {
        id: 'invoice',
        title: 'Invoice',
        type: 'item',
        icon: 'activity',
        url: 'invoice'
      },
      
      {
        id: 'storefront_settings',
        title: 'Storefront Settings',
        type: 'item',
        icon: 'settings',
        url: 'storefront-settings'
      }
    ]
  }
]
