import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';
import { ShopService } from "app/services/shop.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private _router: Router,
    private _shopService: ShopService
  ) {
    try{ 
      this.currentUserSubject = new BehaviorSubject<User>(
        JSON.parse(localStorage.getItem("currentUser")) || null
      );
    }catch(error){
      this.currentUserSubject = new BehaviorSubject<User>(null);
      console.log(error);
      this._router.navigate(['/pages/authentication/login']);
    }
    
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is Admin
   */
  // get isAdmin() {
  //   return (
  //     this.currentUser &&
  //     (this.currentUserSubject.value.type & Role.Admin) === Role.Admin
  //   );
  // }

  /**
   *  Confirms if user is rider
   */
  // get isRider() {
  //   return (
  //     this.currentUser &&
  //     (this.currentUserSubject.value.type & Role.Rider) === Role.Rider
  //   );
  // }

  /**
   * User login
   *
   * @param username
   * @param password
   * @returns user
   */

  public static formData(obj: Object) {
    //to utils
    let ret = new FormData();
    Object.keys(obj).forEach((k) => ret.append(k, obj[k]));
    return ret;
  }

  login(username: string, password: string) {
    // let fd = new FormData();
    return this._http
      .post<any>(
        `${environment.shopUrl}/m/auth/login`,
        AuthenticationService.formData({ username, password })
      )
      .pipe(
        map((d: any) => {
          console.log(d);
          // login successful if there's a jwt token in the response
          if ((d?.code == 0 || d.msg.token)) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(d.msg));
            localStorage.setItem("userName", username);
            this._shopService.spid = d.msg.info.id

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                "You have successfully logged in. Enjoy! 🎉",
                "👋 Welcome!",
                { toastClass: "toast ngx-toastr", closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(d.msg);
            return d.data;
          }else if(d?.code != 0){
            if(d.code == 102){
              console.log("Username or password is incorrect")
            }
          }
          throw new Error(`code:${d?.code} msg:${d?.msg}`);
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("userName");
    localStorage.removeItem("categories");
    // notify
    this.currentUserSubject.next(null);
  }
}
