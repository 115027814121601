import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  // {
  //   id: 'dashboard',
  //   type: 'section',
  //   title: 'Dashboard',
  //   icon: 'command',
  //   children:[
  //     {
  //       id: 'dashboard',
  //       type:'item',
  //       title: 'dashboard',
  //       url: 'dashboard',
  //       icon: 'command'
  //     }
  //   ]
  // },
  { 
    id: 'operation',
    type: 'section',
    title: 'OPERATION',
    children:[
      {
        id: 'orders',
        title: 'Orders',
        type: 'collapsible',
        icon: 'shopping-cart',
        children:[
          {
            id: 'open_orders',
            title: 'Open Orders',
            type: 'item',
            icon: 'circle',
            url: 'open-orders'
          },
          {
            id: 'order_history',
            title: 'Order History',
            type: 'item',
            icon: 'circle',
            url: 'order-history'
          }
        ]
      },
      {
        id: 'routes',
        title: 'Routes',
        type: 'collapsible',
        icon: 'truck',
        children:[
          {
            id: 'routes',
            title: 'Routes',
            type: 'item',
            icon: 'circle',
            url: 'routes/routes'
          },
          {
            id: 'route_history',
            title: 'Route History',
            type: 'item',
            icon: 'circle',
            url: 'routes/route-history'
          },
          // {
          //   id: 'timesheets',
          //   title: 'Timesheets',
          //   type: 'item',
          //   icon: 'circle',
          //   url: 'routes/timesheets'
          // },
          {
            id: 'delivery_area',
            title: 'Delivery Zone',
            type: 'item',
            icon: 'circle',
            url: 'routes/delivery-area'
          }
        ]
      },
      {
        id: 'services',
        title: 'Services',
        type: 'collapsible',
        icon: 'shopping-bag',
        children:[
          {
            id: 'categories',
            title: 'Categories',
            type: 'item',
            icon: 'circle',
            url: 'cate-management'
          },
          {
            id: 'items',
            title: 'Items',
            type: 'item',
            icon: 'circle',
            url: 'shop-services',
          },
        ]
      },
      {
        id: 'coupons',
        title: 'Coupons',
        type: 'item',
        icon: 'award',
        url: 'coupons'
      },
      { 
        id: 'customers',
        title: 'Customers',
        type: 'item',
        icon: 'users',
        url: 'customers'
      },

    // {
    //   id: 'delivery',
    //   title: 'Deliveries',
    //   translate: 'MENU.SAMPLE',
    //   type: 'item',
    //   icon: 'box',
    //   url: 'home'
    // },
    // {
    //   id: 'rider_order_history',
    //   title: 'History',
    //   translate: 'MENU.SAMPLE',
    //   type: 'item',
    //   icon: 'clipboard',
    //   url: 'rider-order-history'
    // },
    // {
    //   id: 'building_detail',
    //   title: 'Building',
    //   translate: 'MENU.SAMPLE',
    //   type: 'item',
    //   icon: 'box',
    //   url: 'building-detail'
    // }
  ]
  },
  { 
    id: 'office',
    type: 'section',
    title: 'OFFICE',
    children:[
      {
        id: 'invoice',
        title: 'Invoice',
        type: 'item',
        icon: 'activity',
        url: 'invoice'
      },
      // {
      //   id: 'accounts',
      //   title: 'Accounts',
      //   type: 'item',
      //   icon: 'user',
      //   url: 'accounts',
      //   disabled: true
      // },
      {
        id: 'storefront_settings',
        title: 'Storefront Settings',
        type: 'item',
        icon: 'settings',
        url: 'storefront-settings'
      }
    ]
  },
  {
    id: 'manual',
    title: 'Manual',
    type: 'item',
    icon: 'box',
    url: 'manual'
  }
]
