import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
  selector: 'app-custom-sidebar',
  templateUrl: './custom-sidebar.component.html',
  styleUrls: ['./custom-sidebar.component.scss']
})
export class CustomSidebarComponent implements OnInit {
  @Input() title: string;  // Sidebar 标题
  @Input() confirm_text: string;
  @Output() submitEvent = new EventEmitter<any>();  // 提交按钮事件

  constructor(private sidebarService: CoreSidebarService) { }

  ngOnInit(): void {
  }

  toggleEventSidebar() {
    this.sidebarService.getSidebarRegistry('custom-sidebar').toggleOpen();
  }



  confirm() {
    this.submitEvent.emit();
  }

}
