export interface MessageTemplate{
    id: number;
    title: string;
    content: string;
}

export const messageTemplates: MessageTemplate[] = [
    {
        id: 1,
        title: 'Order Pickup',
        content: 'Your Order# {{}} has been picked up and you will receive an invoice shortly. {{}} automated message. For further assistance, text {{}} '
    },
    {
        id: 2,
        title: 'Waiting Payment',
        // content: 'You have a new invoice from Order# {{}}. Please complete the payment at your earliest convenience. {{}} automated message. For further assistance, text {{}} '
        content: 'You have a new invoice from Order #{{}}. Please complete the payment in the Bluebox App at your earliest convenience.'
    },
    {
        id: 3,
        title: 'Payment Received',
        content: 'Your payment for Order# {{}} has been received. {{}} automated message. For further assistance, text {{}} '
    },
    {
        id: 4,
        title: 'Order Delivered',
        content: 'Your Order# {{}} is completed and deposited to BlueBox. {{}} automated message. For further assistance, text {{}} '
    }
]