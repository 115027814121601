export const locale = {
  lang: 'fr',
  data: {
    MENU: {
      HOME: 'Maison',
      SAMPLE: 'Échantillon'
    },
    HOME: 'home1',
    BUILDINGS: 'Buildingsfr'
  }
}
