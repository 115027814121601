import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { environment } from 'environments/environment';
import * as crypto from 'crypto-js';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataManagementService {
    // public token: any;

    constructor(private _authenticationService: AuthenticationService,
        private http: HttpClient) {
        // this.token = JSON.parse(localStorage.getItem('currentUser'))?.token;
    }

    encryptByDES(word){
        var key = crypto.enc.Latin1.parse(environment.key);
        var iv = crypto.enc.Latin1.parse(environment.iv);
    
        let encrypted = crypto.AES.encrypt(word, key, { iv: iv, mode: crypto.mode.CBC, adding: 1 });
    
        return encrypted.toString();
    }

    // decryptMsg(msg){
    //     function decryptByDES(word){
    //       //console.log(word)
    //       var key = crypto.enc.Latin1.parse(environment.key);
    //       var iv = crypto.enc.Latin1.parse(environment.iv);
    //       word = word.toString();
    //       let decrypt = crypto.AES.decrypt(word, key, { iv: iv, mode: crypto.mode.CBC, adding: 1 });
    
    //       let decryptedStr = decrypt.toString(crypto.enc.Utf8);
    
    //       return decryptedStr.toString();
    //     }
    
    //     var temp = decryptByDES(atob(msg).split('\n').join(''));
    //     return temp;
    // }

    // decryptByDES(word){
    //     //console.log(word)
    //     var key = crypto.enc.Latin1.parse(environment.key);
    //     var iv = crypto.enc.Latin1.parse(environment.iv);
    //     word = word.toString();
    //     let decrypt = crypto.AES.decrypt(word, key, { iv: iv, mode: crypto.mode.CBC, adding: 1 });
  
    //     let decryptedStr = decrypt.toString(crypto.enc.Utf8);
  
    //     return decryptedStr.toString();
    // }

    httpRequest(url, method, data, needToken?){
        // console.log(this.token);
        console.log(url);
        if(method == 'get'){
            if(!needToken){
                console.log("no token needed")
                return this.http.get<any>(url, {});
            }
            else{
                return this.http.get<any>(url, {headers: new HttpHeaders({
                    'token': JSON.parse(localStorage.getItem('currentUser'))?.token
                })});
            }
            
        }else if(method == 'post'){
            if(!needToken){
                console.log("no token needed")
                return this.http.post<any>(url, data, {});
            }else{
                return this.http.post<any>(url, data, {headers: new HttpHeaders({
                    'token': JSON.parse(localStorage.getItem('currentUser'))?.token
                })});
            }
            
        }
    }

}
